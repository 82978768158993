import React, { useState } from "react";
import { ExclamationCircle } from 'react-bootstrap-icons';
import "../Monitoring/style.css"

function Monitoring(props) {
  const [kode, setKode] = useState("");
  const [validationKode, setValidationKode] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const doValidation = () => {
    setValidationKode(false);
    if (kode === "") {
      setValidationKode(true);
      setKode("");
    } else {
      doMonitoring();
    }
  };
  const doMonitoring = () => {
    let data = { kode };
    setLoading(true);
    setValidationKode(false);
  };
  return (
    <div
      className="mainn-container"
    >
      <div className="form-title mb-3">
        <div className="mb-0">
          <h6>
            Orangtua ingin Link Monitoring Digitalisasi Pembelajaran Putra/inya?
            Hubungi Admin
          </h6>
        </div>
        <div className="text-center">
          <p>Atau masukkan kode disini :</p>
        </div>
      </div>
      <div className="mainn-form">
        <form>
          <div className="mb-2">
            <input
              value={kode}
              onChange={(e) => setKode(e.target.value)}
              type="text"
              name="kode"
              id="kode"
              className={
                error === 40001
                  ? "form-control px-3 is-invalid"
                  : "form-control px-3"
              }
              required
            />
            {validationKode ? (
              <div className="invalid-feedback d-block">
                <ExclamationCircle /> Kode tidak boleh kosong
              </div>
            ) : null}
            {error === 40001 ? (
              <div className="invalid-feedback d-block">
                <ExclamationCircle size="14" /> Kode tidak ditemukan
              </div>
            ) : null}
          </div>
          <button
            type="submit"
            className="monitoring form-control mb-2"
            onClick={(e) => {
              e.preventDefault();
              doValidation();
            }}
          >
            {loading ? (
              <div className="loading-container">
                <span
                  className="spinner-border text-light loading"
                  role="status"
                ></span>{" "}
                Tunggu sebentar
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
export default Monitoring;
